import React, { useState } from "react";
import axiosInstance from "../interceptor/AXIOS";
import moment from "moment/moment";
import toast from "react-hot-toast";
import { BounceLoader } from "react-spinners";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPendingOrders = () => {
    axiosInstance
      .post(`/get_orders_by_status.php`, {
        status: "pending",
      })
      .then((res) => {
        console.log(res.data);
        setOrders(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong...");
      });
  };
  useState(() => {
    getPendingOrders();
  }, []);

  const handleMarked = (id) => {
    console.log(id);
    axiosInstance
      .put("/update_order_status.php", {
        order_id: id,
      })
      .then((res) => {
        toast.success("Order has been marked as completed");
        getPendingOrders();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) {
    return (
      <div
        style={{
          // height: "90%",
          // width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BounceLoader color="rgba(214, 54, 54, 1)" />
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-start text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Phone
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Currency
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5  text-center text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {orders.map((person) => (
                    <tr key={person.customer_email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {person.customer_name}
                            </div>
                            <div className="text-gray-500">
                              {person.customer_VIN}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {person.customer_email}
                        </div>
                        {/* <div className="text-gray-500">{person.department}</div> */}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {person.customer_phone}
                        </div>
                        {/* <div className="text-gray-500">{person.department}</div> */}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.currency_code}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        ${person.amount}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.status == "pending" ? (
                          <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                            {person.status}
                          </span>
                        ) : (
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {person.status}
                          </span>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(person.create_time).format("lll")}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {person.status == "pending" ? (
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => handleMarked(person.order_id)}
                          >
                            Mark as Completed
                            <span className="sr-only">, {person.name}</span>
                          </button>
                        ) : (
                          <button
                            disabled
                            className="text-gray-600 hover:text-gray-900"
                          >
                            Marked
                            <span className="sr-only">, {person.name}</span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
