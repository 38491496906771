
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import axiosInstance from '../interceptor/AXIOS';
import moment from 'moment';
import toast from 'react-hot-toast';

const RevolutCompletedOrders = () => {
    const [expandedRow, setExpandedRow] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState({});
    const [data, setData] = useState([])




    const getData = () => {
        axiosInstance.post('/get_revolut_orders_by_state.php', { status: "completed" }).then((res) => {
            console.log(res.data);
            setData(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }


    const handleExpand = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {

        if (expandedRow !== null) {
            // Simulate an API request with fetch
            axiosInstance.post(`/get_transactions.php`, { paymentId: expandedRow })
                .then((res) => {
                    // console.log(res.data);
                    const { transaction } = res.data;
                    console.log(transaction)
                    setAdditionalInfo((prevInfo) => ({
                        ...prevInfo,
                        [expandedRow]: transaction, // Store the response data keyed by the row ID
                    }));
                })
                .catch((error) => {
                    console.error('Error fetching additional information:', error);
                });
        }
    }, [expandedRow]);


    const handleUpdate = (id) => {
        axiosInstance.put('/update_revolute_order_status.php', { id }).then((res) => {
            toast.success("Order marked as completed");
        }).catch((err) => {
            toast.error("Something went wrong");
        })
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col"
                                            className="py-3.5 pl-4 pr-3 text-start text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                                        <th scope="col"
                                            className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900">Email</th>
                                        <th scope="col"
                                            className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900">Phone</th>
                                        <th scope="col"
                                            className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900">Package</th>
                                        <th scope="col"
                                            className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900">Status</th>
                                        <th scope="col"
                                            className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900">Date</th>
                                        <th scope="col-2"
                                            className="px-3 py-3.5  text-start text-sm font-semibold text-gray-900">Actions</th>
                                        {/* <th scope='col'></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.reverse()?.map((item) => (
                                        <React.Fragment key={item.id}>
                                            <tr >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="font-medium text-gray-900">
                                                                {item.customer_name}
                                                            </div>
                                                            <div className="text-gray-500">
                                                                {item.customer_VIN}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="text-gray-900">{item.customer_email}</div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="text-gray-900">{item.customer_phone}</div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="text-gray-900">${item.package}</div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {item?.state === 'completed' && (
                                                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                            {item?.state}
                                                        </span>
                                                    )}

                                                </td>



                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    <div className="text-gray-900">{moment(item.created_at).format('lll')}</div>
                                                </td>


                                                <td className="py-2 px-4 border-b">
                                                    <button
                                                        className="text-blue-500 hover:underline"
                                                        onClick={() => handleExpand(item.paymentId)}
                                                    >
                                                        {expandedRow === item.paymentId ? 'Hide' : 'Transaction Details'}
                                                    </button>
                                                </td>

                                                {/* <td className="py-2 px-4 border-b">
                                                    <button
                                                        className="text-blue-500 hover:underline"
                                                        onClick={() => handleUpdate(item.id)}
                                                    >
                                                        Mark as Completed
                                                    </button>
                                                </td> */}
                                            </tr>
                                            {expandedRow === item.paymentId && (
                                                <tr>
                                                    <td colSpan="7" className="py-2 px-4 border-b bg-gray-100">
                                                        <div className="p-4">
                                                            {additionalInfo ? (
                                                                <>
                                                                    <p><strong>Additional Information:</strong></p>
                                                                    <p className='uppercase'><b>Order ID:</b> {additionalInfo[item.paymentId]?.id}</p>
                                                                    <p className='uppercase'><b>Date:</b> {moment(additionalInfo[item.paymentId]?.created_at).format('lll')}</p>
                                                                    <p className='uppercase'><b>Currency:</b> {additionalInfo[item.paymentId]?.currency}</p>
                                                                    <p className='uppercase'><b>Type:</b> {additionalInfo[item.paymentId]?.type}</p>
                                                                    <p className='uppercase'><b>AMOUNT:</b> ${(additionalInfo[item.paymentId]?.amount / 100).toFixed(2)}</p>
                                                                    <p className='uppercase'><b>Status:</b>  {
                                                                        additionalInfo[item.paymentId]?.state === 'completed' ? (
                                                                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                                {additionalInfo[item.paymentId]?.state}
                                                                            </span>
                                                                        ) : (
                                                                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                                                                {additionalInfo[item.paymentId]?.state}
                                                                            </span>
                                                                        )
                                                                    }</p>
                                                                    <hr className='mt-5 mb-5' />
                                                                    {
                                                                        additionalInfo[item.paymentId]?.payments && (
                                                                            <p className='uppercase'><b>TRANSACTION HISTORY:</b></p>
                                                                        )
                                                                    }

                                                                    <ul>
                                                                        {additionalInfo[item.paymentId]?.payments?.map((e) => (
                                                                            <>
                                                                                <li>
                                                                                    {
                                                                                        e?.state === 'failed' && (
                                                                                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                                                                                {e?.state} | {moment(e?.created_at).format('lll')}
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </li>
                                                                                <li>
                                                                                    {
                                                                                        e?.state === 'declined' && (
                                                                                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                                                                                {e?.decline_reason} | | {moment(e?.created_at).format('lll')}
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </li>
                                                                                <li>
                                                                                    {
                                                                                        e?.state === 'captured' && (
                                                                                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                                                {e?.state} | | {moment(e?.created_at).format('lll')}
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </li>



                                                                            </>
                                                                        )).reverse()}
                                                                    </ul>

                                                                    {/* Add more detailed information here */}
                                                                </>
                                                            ) : (
                                                                <p>Loading...</p>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RevolutCompletedOrders