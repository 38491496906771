import axios from "axios";

export const getToken = async () => {
  try {
    const token = localStorage.getItem("token");
    if (token !== null) {
      return token;
    }
  } catch (error) {
    console.log(error);
  }
};

export const setToken = async (token) => {
  localStorage.setItem("token", token);
};

// export const CONTENT_URL = 'https://backend.thevinreport.com/';

export const removeToken = async (token) => {
  localStorage.removeItem("token");
  
};

const axiosInstance = axios.create({
  //https://backend.thevinreport.com/api
  //http://localhost/backend/api/
  baseURL: "https://backend.thevinreport.com/api",
  // headers: {
  //   'Content-Type': 'multipart/form-data',
  // },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    //config.headers.common['Content-Type'] = 'multipart/form-data';
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      removeToken().then(() => {
        window.location.href = "/#/login";
      });
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    //config.headers.common['Content-Type'] = 'multipart/form-data';
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      removeToken().then(() => {
        window.location.href = "/#/login";
      });
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;