import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import axiosInstance from "../interceptor/AXIOS";
import toast from "react-hot-toast";


const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("username is required"),
    password: Yup.string().required("password is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      setLoading(true);
      axiosInstance
        .post("/login.php", values)
        .then((res) => {
          const { jwt } = res.data;
          localStorage.setItem("token", jwt);
          setLoading(false);
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error('Invalid Login Credentials')
        });
    },
  });
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 mt-40">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="assets/images/logo.png"
            alt="Workflow"
          />
          {/* <h2 className="mt-6 text-center text-3xl font-medium text-gray-900">
            Sign in to your account
          </h2> */}
          <p className="mt-2 text-center text-sm text-gray-600"></p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
          
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="text"
                name="username"
                id="username"
                placeholder="Username"
                onChange={formik.handleChange("username")}
                onBlur={formik.handleBlur("username")}
                aria-invalid="true"
                aria-describedby="username"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
            <p className="mt-2 text-sm text-red-600" id="username">
              {formik.touched.username && formik.errors.username}
            </p>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                aria-invalid="true"
                aria-describedby="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
            <p className="mt-2 text-sm text-red-600" id="password">
              {formik.touched.password && formik.errors.password}
            </p>
          </div>

          <div className="text-right sm:col-span-2">
            <button
              type="submit"
              className="inline-flex w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Loader isLoading={loading} />
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
