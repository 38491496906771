import { Outlet, Navigate, useLocation } from 'react-router-dom';


const DashboardProtected = () => {
  let location = useLocation();
  const auth = localStorage.getItem("token");
  return auth ? <Outlet /> :  <Navigate to="/login" replace state={{ from: location }} />;
};

export default DashboardProtected;
