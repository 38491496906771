import React, { useState } from "react";
import axiosInstance from "../interceptor/AXIOS";
const stats = [
  { name: "Total Customers", stat: "71,897" },
  { name: "Total Orders", stat: "43343" },
  // { name: 'Avg. Click Rate', stat: '24.57%' },
];

const Home = () => {
  useState(() => {
    axiosInstance
      .get("/get_all_users.php")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    // <div>
    //   {/* <h3 className="text-lg leading-6 font-medium text-gray-900">Last 30 days</h3> */}
    //   <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
    //     {stats.map((item) => (
    //       <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
    //         <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
    //         <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
    //       </div>
    //     ))}
    //   </dl>
    // </div>
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-red-600 tracking-wide uppercase">
            Hello.
          </h2>
          <img  src="assets/images/logo.png" />
          {/* <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Welcome to The <span className="text-red-800">VIN</span> Report
          </p> */}
          {/* <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Start building for free, then add a site plan to go live. Account
            plans unlock additional features.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
